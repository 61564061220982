import * as React from 'react';
import { CaretDown16 } from '@carbon/icons-react';
import { TriggerProps } from './props';

import { Trigger, TriggerText, TriggerDesktop } from './styles';

const TriggerDefault: React.FC<React.PropsWithChildren<TriggerProps>> = ({
  isOpen,
  handleClick,
  handleFocus,
  handleBlur,
  triggerText,
  triggerStyles,
  isButtonPrimary,
}) => {
  return (
    <Trigger
      isButtonPrimary={isButtonPrimary}
      onClick={handleClick}
      onFocus={handleFocus}
      onBlur={handleBlur}
      aria-haspopup={true}
      aria-expanded={isOpen}
      type="button"
      className="rounded px-3"
      style={triggerStyles}
    >
      <TriggerDesktop className="flex w-full items-center justify-between">
        <TriggerText className="text-xs">{triggerText}</TriggerText> <CaretDown16 />
      </TriggerDesktop>
    </Trigger>
  );
};

export default TriggerDefault;
