import styled, { css } from 'styled-components';
import { RawButton } from 'styles/elements';
import { mobile } from 'styles/breakpoints';

export const Container = styled.div`
  position: relative;
`;

export const Trigger = styled(RawButton)<{ isButtonPrimary?: boolean }>`
  height: 34px;
  padding: 0 0.75rem;
  transition: background-color 200ms ease;
  background-color: ${({ theme, isButtonPrimary }) =>
    isButtonPrimary ? theme.color.buttonPrimary : theme.color.backgroundSecondary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 200px;
  border: 1px solid
    ${({ theme, isButtonPrimary }) =>
      isButtonPrimary ? theme.color.buttonPrimary : theme.color.border};

  &:hover {
    background-color: ${({ theme }) => theme.color.buttonPrimary};
  }

  svg {
    fill: ${({ theme }) => theme.color.textPrimary};
  }
`;

export const TriggerText = styled.span`
  display: inline-block;
  margin-right: 4px;
`;

export const TriggerDesktop = styled.span`
  display: flex;
  align-items: center;
`;

export const TriggerMobile = styled.span`
  width: ${({ theme }) => theme.spacing.navHeight}px;
  height: ${({ theme }) => theme.spacing.navHeight}px;
  display: none;

  ${mobile()} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const DropdownWrapper = styled.nav`
  position: absolute;
  right: 0;
  top: 38px;
  background-color: ${({ theme }) => theme.color.dropdownBackground};
  color: ${({ theme }) => theme.color.textPrimary};
  min-width: 200px;
  box-shadow: ${({ theme }) => theme.shadow.stroke}, 0 8px 16px rgba(0, 0, 0, 0.8);
  border: 1px solid ${({ theme }) => theme.color.border};
  z-index: ${({ theme }) => theme.zIndex.z1};
  max-height: calc(100vh - 160px);
  overflow-y: auto;

  ${mobile()} {
    border: 1px solid ${({ theme }) => theme.color.invertedSecondary};
    top: 32px;
  }
`;

const dropdownItemStyles = css`
  display: block;
  transition: background-color 150ms ease;

  &:hover {
    background-color: ${({ theme }) => theme.color.buttonPrimary};
  }

  &:focus {
    outline: none;
    background-color: ${({ theme }) => theme.color.buttonPrimary};
  }
`;

export const DropdownItemContent = styled.div`
  padding: 12px;
  font-size: 0.75em;
  display: flex;
  align-items: center;
  height: 40px;

  ${mobile()} {
    padding: 12px 24px;
  }
`;

export const DropdownLink = styled.span`
  ${dropdownItemStyles};
`;

export const DropdownButton = styled(RawButton)`
  ${dropdownItemStyles};
  width: 100%;
  padding: 0;
  text-align: left;
`;
