export default {
  data: [
    {
      currencypair: 'AUD-CAD',
      has_news: 'yes',
    },
    {
      currencypair: 'AUD-CHF',
      has_news: 'yes',
    },
    {
      currencypair: 'AUD-CZK',
      has_news: 'no',
    },
    {
      currencypair: 'AUD-DKK',
      has_news: 'no',
    },
    {
      currencypair: 'AUD-HKD',
      has_news: 'no',
    },
    {
      currencypair: 'AUD-HUF',
      has_news: 'no',
    },
    {
      currencypair: 'AUD-JPY',
      has_news: 'yes',
    },
    {
      currencypair: 'AUD-MXN',
      has_news: 'no',
    },
    {
      currencypair: 'AUD-NOK',
      has_news: 'no',
    },
    {
      currencypair: 'AUD-NZD',
      has_news: 'yes',
    },
    {
      currencypair: 'AUD-PLN',
      has_news: 'no',
    },
    {
      currencypair: 'AUD-SEK',
      has_news: 'no',
    },
    {
      currencypair: 'AUD-SGD',
      has_news: 'no',
    },
    {
      currencypair: 'AUD-USD',
      has_news: 'yes',
    },
    {
      currencypair: 'AUD-ZAR',
      has_news: 'no',
    },
    {
      currencypair: 'CAD-CHF',
      has_news: 'yes',
    },
    {
      currencypair: 'CAD-CZK',
      has_news: 'no',
    },
    {
      currencypair: 'CAD-DKK',
      has_news: 'no',
    },
    {
      currencypair: 'CAD-HKD',
      has_news: 'no',
    },
    {
      currencypair: 'CAD-HUF',
      has_news: 'no',
    },
    {
      currencypair: 'CAD-JPY',
      has_news: 'yes',
    },
    {
      currencypair: 'CAD-MXN',
      has_news: 'no',
    },
    {
      currencypair: 'CAD-NOK',
      has_news: 'no',
    },
    {
      currencypair: 'CAD-PLN',
      has_news: 'no',
    },
    {
      currencypair: 'CAD-SEK',
      has_news: 'no',
    },
    {
      currencypair: 'CAD-SGD',
      has_news: 'no',
    },
    {
      currencypair: 'CAD-ZAR',
      has_news: 'no',
    },
    {
      currencypair: 'CHF-CZK',
      has_news: 'no',
    },
    {
      currencypair: 'CHF-DKK',
      has_news: 'no',
    },
    {
      currencypair: 'CHF-HKD',
      has_news: 'no',
    },
    {
      currencypair: 'CHF-HUF',
      has_news: 'no',
    },
    {
      currencypair: 'CHF-JPY',
      has_news: 'yes',
    },
    {
      currencypair: 'CHF-MXN',
      has_news: 'no',
    },
    {
      currencypair: 'CHF-NOK',
      has_news: 'no',
    },
    {
      currencypair: 'CHF-PLN',
      has_news: 'no',
    },
    {
      currencypair: 'CHF-SEK',
      has_news: 'no',
    },
    {
      currencypair: 'CHF-SGD',
      has_news: 'no',
    },
    {
      currencypair: 'CHF-TRY',
      has_news: 'no',
    },
    {
      currencypair: 'CHF-ZAR',
      has_news: 'no',
    },
    {
      currencypair: 'DKK-CZK',
      has_news: 'no',
    },
    {
      currencypair: 'DKK-HKD',
      has_news: 'no',
    },
    {
      currencypair: 'DKK-HUF',
      has_news: 'no',
    },
    {
      currencypair: 'DKK-MXN',
      has_news: 'no',
    },
    {
      currencypair: 'DKK-NOK',
      has_news: 'no',
    },
    {
      currencypair: 'DKK-PLN',
      has_news: 'no',
    },
    {
      currencypair: 'DKK-SEK',
      has_news: 'no',
    },
    {
      currencypair: 'DKK-SGD',
      has_news: 'no',
    },
    {
      currencypair: 'DKK-ZAR',
      has_news: 'no',
    },
    {
      currencypair: 'EUR-AUD',
      has_news: 'yes',
    },
    {
      currencypair: 'EUR-CAD',
      has_news: 'yes',
    },
    {
      currencypair: 'EUR-CHF',
      has_news: 'yes',
    },
    {
      currencypair: 'EUR-CZK',
      has_news: 'yes',
    },
    {
      currencypair: 'EUR-DKK',
      has_news: 'yes',
    },
    {
      currencypair: 'EUR-GBP',
      has_news: 'yes',
    },
    {
      currencypair: 'EUR-HKD',
      has_news: 'no',
    },
    {
      currencypair: 'EUR-HUF',
      has_news: 'yes',
    },
    {
      currencypair: 'EUR-JPY',
      has_news: 'yes',
    },
    {
      currencypair: 'EUR-MXN',
      has_news: 'no',
    },
    {
      currencypair: 'EUR-NOK',
      has_news: 'yes',
    },
    {
      currencypair: 'EUR-NZD',
      has_news: 'yes',
    },
    {
      currencypair: 'EUR-PLN',
      has_news: 'yes',
    },
    {
      currencypair: 'EUR-RUB',
      has_news: 'yes',
    },
    {
      currencypair: 'EUR-SEK',
      has_news: 'yes',
    },
    {
      currencypair: 'EUR-SGD',
      has_news: 'no',
    },
    {
      currencypair: 'EUR-TRY',
      has_news: 'yes',
    },
    {
      currencypair: 'EUR-USD',
      has_news: 'yes',
    },
    {
      currencypair: 'EUR-ZAR',
      has_news: 'yes',
    },
    {
      currencypair: 'GBP-AUD',
      has_news: 'yes',
    },
    {
      currencypair: 'GBP-CAD',
      has_news: 'yes',
    },
    {
      currencypair: 'GBP-CHF',
      has_news: 'yes',
    },
    {
      currencypair: 'GBP-CZK',
      has_news: 'no',
    },
    {
      currencypair: 'GBP-DKK',
      has_news: 'no',
    },
    {
      currencypair: 'GBP-EUR',
      has_news: 'yes',
    },
    {
      currencypair: 'GBP-HKD',
      has_news: 'no',
    },
    {
      currencypair: 'GBP-HUF',
      has_news: 'no',
    },
    {
      currencypair: 'GBP-JPY',
      has_news: 'yes',
    },
    {
      currencypair: 'GBP-MXN',
      has_news: 'no',
    },
    {
      currencypair: 'GBP-NOK',
      has_news: 'no',
    },
    {
      currencypair: 'GBP-NZD',
      has_news: 'yes',
    },
    {
      currencypair: 'GBP-PLN',
      has_news: 'no',
    },
    {
      currencypair: 'GBP-SEK',
      has_news: 'no',
    },
    {
      currencypair: 'GBP-SGD',
      has_news: 'no',
    },
    {
      currencypair: 'GBP-USD',
      has_news: 'yes',
    },
    {
      currencypair: 'GBP-ZAR',
      has_news: 'yes',
    },
    {
      currencypair: 'JPY-CZK',
      has_news: 'no',
    },
    {
      currencypair: 'JPY-DKK',
      has_news: 'no',
    },
    {
      currencypair: 'JPY-HKD',
      has_news: 'no',
    },
    {
      currencypair: 'JPY-HUF',
      has_news: 'no',
    },
    {
      currencypair: 'JPY-MXN',
      has_news: 'no',
    },
    {
      currencypair: 'JPY-NOK',
      has_news: 'no',
    },
    {
      currencypair: 'JPY-PLN',
      has_news: 'no',
    },
    {
      currencypair: 'JPY-SEK',
      has_news: 'no',
    },
    {
      currencypair: 'JPY-SGD',
      has_news: 'no',
    },
    {
      currencypair: 'JPY-ZAR',
      has_news: 'no',
    },
    {
      currencypair: 'NOK-CZK',
      has_news: 'no',
    },
    {
      currencypair: 'NOK-HKD',
      has_news: 'no',
    },
    {
      currencypair: 'NOK-HUF',
      has_news: 'no',
    },
    {
      currencypair: 'NOK-MXN',
      has_news: 'no',
    },
    {
      currencypair: 'NOK-PLN',
      has_news: 'no',
    },
    {
      currencypair: 'NOK-SEK',
      has_news: 'yes',
    },
    {
      currencypair: 'NOK-SGD',
      has_news: 'no',
    },
    {
      currencypair: 'NOK-ZAR',
      has_news: 'no',
    },
    {
      currencypair: 'NZD-CAD',
      has_news: 'yes',
    },
    {
      currencypair: 'NZD-CHF',
      has_news: 'yes',
    },
    {
      currencypair: 'NZD-CZK',
      has_news: 'no',
    },
    {
      currencypair: 'NZD-DKK',
      has_news: 'no',
    },
    {
      currencypair: 'NZD-HKD',
      has_news: 'no',
    },
    {
      currencypair: 'NZD-HUF',
      has_news: 'no',
    },
    {
      currencypair: 'NZD-JPY',
      has_news: 'yes',
    },
    {
      currencypair: 'NZD-MXN',
      has_news: 'no',
    },
    {
      currencypair: 'NZD-NOK',
      has_news: 'no',
    },
    {
      currencypair: 'NZD-PLN',
      has_news: 'no',
    },
    {
      currencypair: 'NZD-SEK',
      has_news: 'no',
    },
    {
      currencypair: 'NZD-SGD',
      has_news: 'no',
    },
    {
      currencypair: 'NZD-USD',
      has_news: 'yes',
    },
    {
      currencypair: 'NZD-ZAR',
      has_news: 'no',
    },
    {
      currencypair: 'USD-BRL',
      has_news: 'yes',
    },
    {
      currencypair: 'USD-CAD',
      has_news: 'yes',
    },
    {
      currencypair: 'USD-CHF',
      has_news: 'yes',
    },
    {
      currencypair: 'USD-CNH',
      has_news: 'yes',
    },
    {
      currencypair: 'USD-CNY',
      has_news: 'yes',
    },
    {
      currencypair: 'USD-CZK',
      has_news: 'no',
    },
    {
      currencypair: 'USD-DKK',
      has_news: 'no',
    },
    {
      currencypair: 'USD-HKD',
      has_news: 'yes',
    },
    {
      currencypair: 'USD-HUF',
      has_news: 'yes',
    },
    {
      currencypair: 'USD-IDR',
      has_news: 'yes',
    },
    {
      currencypair: 'USD-INR',
      has_news: 'yes',
    },
    {
      currencypair: 'USD-JPY',
      has_news: 'yes',
    },
    {
      currencypair: 'USD-KRW',
      has_news: 'yes',
    },
    {
      currencypair: 'USD-MXN',
      has_news: 'yes',
    },
    {
      currencypair: 'USD-NOK',
      has_news: 'yes',
    },
    {
      currencypair: 'USD-PLN',
      has_news: 'no',
    },
    {
      currencypair: 'USD-RUB',
      has_news: 'yes',
    },
    {
      currencypair: 'USD-SEK',
      has_news: 'yes',
    },
    {
      currencypair: 'USD-SGD',
      has_news: 'yes',
    },
    {
      currencypair: 'USD-TRY',
      has_news: 'yes',
    },
    {
      currencypair: 'USD-ZAR',
      has_news: 'yes',
    },
    {
      currencypair: 'XAG-USD',
      has_news: 'yes',
    },
    {
      currencypair: 'XAU-USD',
      has_news: 'yes',
    },
    {
      currencypair: 'XPD-USD',
      has_news: 'yes',
    },
  ],
};
