import * as React from 'react';
import styled from 'styled-components';
import { IconType } from 'react-icons';

interface Props {
  Icon: IconType;
  title: string | JSX.Element;
  right?: JSX.Element;
  className?: string;
  style?: object;
}

const Title = styled.div``;

const IconWrapper = styled.span`
  color: ${({ theme }) => theme.color.callText};
`;

const SectionTitle: React.FC<React.PropsWithChildren<Props>> = ({ Icon, title, right, className = '', style }) => {
  return (
    <Title className={`mb-2 flex items-center justify-between ${className}`} style={style}>
      <div className="flex items-center text-sm leading-none">
        <IconWrapper className="mr-3">
          <Icon />
        </IconWrapper>{' '}
        <h2 className="leading-none">{title}</h2>
      </div>
      {right}
    </Title>
  );
};

export default SectionTitle;
