import { themeDark } from 'styles/theme';
import { currencies } from 'utils/shared/money/forex';

export enum TradingViewAccessStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export const THIRTY_MINUTE_INTERVAL = '30';
export const DAY_INTERVAL = 'D';
export const DEFAULT_TIMEZONE = 'America/New_York';
export const ONE_MONTH_RANGE = '1M';
export const SIX_MONTH_RANGE = '6M';
export const TWELVE_MONTH_RANGE = '12M';

export const TV_RESOURCE_CENTER_LINK = 'https://www.insiderfinance.io/my/tradingview';
export const TV_CHART_URL = 'https://www.tradingview.com/chart/?symbol=';
export const TV_PERMANENT_OFFER = 'offer_id=10';
export const TV_REFERRAL_QUERY = 'aff_id';
export const TV_REFERRAL_ID = '28670';
export const TV_SOURCE_REF = 'source';

const getDefaultTimezone = () => {
  try {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return tz;
  } catch (error) {
    return '';
  }
};

export const getTradingviewUsernameFromProfileUrl = (input: string) => {
  const url = new URL(
    input.includes('http://') || input.includes('https://') ? input : `https://${input}`,
  );
  const path = url.pathname;
  const username = path.split('/')[2];

  return username;
};

// offer_id=10&&aff_id=28670&source=ticker

export const buildTradingViewAffiliateLink = (url: string, source: string = 'dashboard') => {
  const separator = url.includes('?') ? '&' : '?';
  const query = `${TV_PERMANENT_OFFER}&${TV_REFERRAL_QUERY}=${TV_REFERRAL_ID}&${TV_SOURCE_REF}=${source}`;
  return `${url}${separator}${query}`;
};
export const getTradingViewProfileUrl = (username: string) =>
  `https://www.tradingview.com/u/${username}`;

export const getTradingViewProfileUrlWithAffiliate = (username: string) =>
  buildTradingViewAffiliateLink(getTradingViewProfileUrl(username), 'profileconf');

export const TV_CHART_SCRIPT_SRC = 'https://s3.tradingview.com/tv.js';

export const TV_ADVANCED_CHART_ID = 'chart-analysis';
export const TV_WIDGET_PROPS = {
  autosize: true,
  interval: THIRTY_MINUTE_INTERVAL,
  timezone: getDefaultTimezone(),
  theme: 'dark',
  style: '1',
  locale: 'en',
  range: ONE_MONTH_RANGE,
  toolbar_bg: themeDark.color.backgroundPrimary,
  enable_publishing: false,
  withdateranges: true,
  hide_side_toolbar: true,
  allow_symbol_change: false,
  details: false,
  hotlist: false,
  calendar: false,
  container_id: TV_ADVANCED_CHART_ID,
  whitelabel: false, // NOTE: Set this to true?
  studies: [
    'MACD@tv-basicstudies',
    'PSAR@tv-basicstudies',
    'PivotPointsHighLow@tv-basicstudies',
    'ZigZag@tv-basicstudies',
  ],
  // referral_id: TV_REFERRAL_ID,
};

export const TV_CRYPTO_CHART_PROPS = {
  ...TV_WIDGET_PROPS,
  interval: DAY_INTERVAL,
  range: TWELVE_MONTH_RANGE,
};

export const TV_FOREX_CHART_PROPS = {
  ...TV_WIDGET_PROPS,
  interval: DAY_INTERVAL,
  range: TWELVE_MONTH_RANGE,
};

export const TV_SCREENER_PROPS = {
  width: '100%',
  height: '100%',
  displayCurrency: 'USD',
  colorTheme: 'dark',
  locale: 'en',
  showToolbar: true,
  isTransparent: true,
};

export const TV_SCREENER_SRC =
  'https://s3.tradingview.com/external-embedding/embed-widget-screener.js';

export enum ScreenerColumns {
  Overview = 'overview',
  Performance = 'performance',
  Oscillators = 'oscillators',
  Trends = 'moving_averages',
}

export const TV_CRYPTO_SCREENER_PROPS = {
  ...TV_SCREENER_PROPS,
  defaultColumn: ScreenerColumns.Overview,
  screener_type: 'crypto_mkt',
  // market: 'crypto',
};

export const TV_FOREX_SCREENER_PROPS = {
  ...TV_SCREENER_PROPS,
  defaultColumn: ScreenerColumns.Overview,
  market: 'forex',
};

export const TV_SYMBOL_INFO_SRC =
  'https://s3.tradingview.com/external-embedding/embed-widget-symbol-info.js';
export const TV_SYMBOL_INFO_PROPS = {
  width: '100%',
  locale: 'en',
  colorTheme: 'dark',
  isTransparent: false,
};

export const TV_SINGLE_QUOTE_SRC =
  'https://s3.tradingview.com/external-embedding/embed-widget-single-quote.js';
export const TV_SINGLE_QUOTE_PROPS = {
  width: '100%',
  height: '74px',
  locale: 'en',
  colorTheme: 'dark',
  isTransparent: true,
};

export const TV_TECHNICAL_ANALYSIS_SRC =
  'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js';
export const TV_TECHNICAL_ANALYSIS_PROPS = {
  interval: '4h',
  width: '100%',
  height: '100%',
  isTransparent: true,
  showIntervalTabs: true,
  locale: 'en',
  colorTheme: 'dark',
};

export const TV_FUNDAMENTAL_ANALYSIS_SRC =
  'https://s3.tradingview.com/external-embedding/embed-widget-financials.js';
export const TV_FUNDAMENTAL_ANALYSIS_PROPS = {
  colorTheme: 'dark',
  isTransparent: true,
  displayMode: 'regular',
  width: '100%',
  height: '100%',
  locale: 'en',
};

export const TV_FOREX_CROSS_RATES_SRC =
  'https://s3.tradingview.com/external-embedding/embed-widget-forex-cross-rates.js';
export const TV_CROSS_RATES_PROPS = {
  width: '100%',
  height: '100%',
  currencies,
  isTransparent: true,
  colorTheme: 'dark',
  locale: 'en',
};

export const TV_FOREX_HEAT_MAP_SRC =
  'https://s3.tradingview.com/external-embedding/embed-widget-forex-heat-map.js';
export const TV_FOREX_HEAT_MAP_PROPS = {
  width: '100%',
  height: '100%',
  currencies,
  isTransparent: true,
  colorTheme: 'dark',
  locale: 'en',
};
