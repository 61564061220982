import currencyPairs from 'constants/tickers/forex';

export const currencies = [
  'USD',
  'EUR',
  'JPY',
  'GBP',
  'CHF',
  'AUD',
  'CAD',
  'NZD',
  'CNY',
  'TRY',
  'SEK',
  'NOK',
  'DKK',
  'ZAR',
  'HKD',
  'SGD',
  'THB',
  'MXN',
  'IDR',
  'KRW',
  'PLN',
  'ISK',
  'KWD',
  'PHP',
  'MYR',
  'INR',
  'TWD',
  'SAR',
  'AED',
  'RUB',
  'ILS',
  'ARS',
  'CLP',
  'COP',
  'PEN',
  'UYU',
];

export const newsTickers = currencyPairs.data;

export const removeDash = (ticker: string) => ticker.replace('-', '');

export const addDash = (ticker: string) =>
  `${ticker.slice(0, 3)}-${ticker.slice(-3)}`.toUpperCase();

export const mapTickerToNews = (ticker: string) => {
  for (let i = 0; i < newsTickers.length; i++) {
    const pair = newsTickers[i].currencypair;
    const [firstTicker, secondTicker] = pair.split('-');

    if (ticker.includes(firstTicker) && ticker.includes(secondTicker)) {
      return pair;
    }
  }

  return ticker;
};
