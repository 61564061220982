import * as React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  isDark?: boolean;
  style?: any;
}

const CardWrapper = styled.div<{ hoverable?: boolean; isDark?: boolean }>`
  background-color: ${({ theme, isDark }) =>
    isDark ? theme.color.backgroundSecondary : theme.color.paper};
  box-shadow: ${({ theme, isDark }) => (isDark ? theme.shadow.medium : theme.shadow.low)};
  border-radius: 4px;
  transition: border 0.2s ease;
  line-height: 1;

  &:hover {
    ${(props) => props.hoverable && `border: 1px solid ${props.theme.color.brand};`}
  }
`;

const Card: React.FC<React.PropsWithChildren<Props>> = ({ children, className = '', isDark, style }) => (
  <CardWrapper className={`p-4${className ? ` ${className}` : ''} `} isDark={isDark} style={style}>
    {children}
  </CardWrapper>
);

export default Card;
