const WALLSTREET_TIME_ZONE = 'America/New_York';
const LOCALE = 'en-US';

interface Timezone {
  timestamp: string | number;
  requestedTimezone?: string;
  isWallstreetTimezone?: boolean;
}

const convertTimestampToTime = ({ timestamp, isWallstreetTimezone = false }: Timezone) => {
  // @ts-ignore add this to global window object
  const hasInjectedPageData = typeof window !== 'undefined' && !!window?.injectedPageData;
  const timeZoneOption =
    isWallstreetTimezone || hasInjectedPageData ? WALLSTREET_TIME_ZONE : undefined;

  const localeDateString = new Date(timestamp)?.toLocaleString(LOCALE, {
    timeZone: timeZoneOption,
  });

  const splitTimeString = localeDateString?.split(', ');
  const dateString = splitTimeString[0];
  const timeString = splitTimeString[1];
  const [fullTime, timeSubscript] = timeString?.split(' ');
  const timeHoursMinutes = fullTime?.split(':')?.slice(0, 2)?.join(':');

  return { time: timeHoursMinutes, timeSubscript, date: dateString };
};

export default convertTimestampToTime;
