import * as React from 'react';
import Link from 'components/Link';
import { ItemProps } from './props';

import { DropdownItemContent, DropdownLink, DropdownButton } from './styles';

const DropdownItem: React.FC<React.PropsWithChildren<ItemProps>> = ({
  item,
  handleFocus,
  handleBlur,
  closeHandler,
  className = '',
}) => {
  if (item.onClick) {
    return (
      <DropdownButton
        className={className}
        onClick={() => {
          if (item.onClick) {
            item.onClick();
          }

          if (!item.shouldPreventClose) {
            closeHandler();
          }
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        role="menuitem"
      >
        <DropdownItemContent>{item.name}</DropdownItemContent>
      </DropdownButton>
    );
  }

  return item.external ? (
    <Link href={item.href!} isExternal onFocus={handleFocus} onBlur={handleBlur} role="menuitem">
      <DropdownLink className={className}>
        <DropdownItemContent>{item.name}</DropdownItemContent>
      </DropdownLink>
    </Link>
  ) : (
    <Link href={item.href!} onFocus={handleFocus} onBlur={handleBlur} role="menuitem">
      <DropdownLink className={className}>
        <DropdownItemContent>{item.name}</DropdownItemContent>
      </DropdownLink>
    </Link>
  );
};

export default DropdownItem;
