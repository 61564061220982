import styled from 'styled-components';
import { fadeIn } from 'styles/animations';

const SIDEBAR_WIDTH = 60;
const SIDEBAR_EXPANDED_WITH = 256;

export const Page = styled.div``;

export const PageWrapper = styled.div``;

export const PageContent = styled.main`
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.z1};
  padding-top: env(safe-area-inset-top, 0);
  padding-bottom: env(safe-area-inset-bottom, 0);

  &:focus {
    outline: none;
  }
`;

export const Sidebar = styled.nav<{ isExpanded?: boolean }>`
  transition: width 400ms ease;
  width: ${({ isExpanded }) => (isExpanded ? SIDEBAR_EXPANDED_WITH : SIDEBAR_WIDTH)}px;
  border-right: 1px solid ${({ theme }) => theme.color.border};
  opacity: 0;
  animation: ${fadeIn} 500ms linear 1 normal forwards;
  position: relative;

  &:hover {
    z-index: ${({ theme }) => theme.zIndex.z2};
  }
`;

export const SidebarItem = styled.div<{ isActive?: boolean }>`
  width: 100%;
  border-left: 2px solid
    ${({ isActive, theme }) => (isActive ? theme.color.buttonPrimary : 'transparent')};
  border-right: 2px solid transparent;
  color: ${({ isActive, theme }) => (isActive ? theme.color.textPrimary : theme.color.textLight)};

  svg {
    transition: color 250ms ease;
  }

  &:hover {
    svg {
      color: ${({ theme }) => theme.color.textPrimary};
    }
  }
`;

export const SidebarItemAlert = styled(SidebarItem)`
  &:hover {
    svg {
      color: ${({ theme }) => theme.color.putBackground};
    }
  }
`;

export const IconBackground = styled.div`
  background-color: ${({ theme }) => theme.color.backgroundDistinct};
`;
