import * as React from 'react';
import useOnClickOutside from 'hooks/useOnOutsideClick';
import DropdownItem from './DropdownItem';
import TriggerDefault from './TriggerDefault';
import { ListProps } from './props';

import { Container, DropdownWrapper } from './styles';

const Dropdown: React.FC<React.PropsWithChildren<ListProps>> = ({
  Trigger = TriggerDefault,
  isButtonPrimary,
  triggerText,
  items,
  handleFocus,
  handleBlur,
  triggerStyles,
  wrapperStyles,
  containerStyles,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const triggerEl = React.useRef(null);
  const closeHandler = React.useCallback(() => {
    setIsDropdownOpen(false);
  }, [isDropdownOpen, setIsDropdownOpen]);
  useOnClickOutside(triggerEl, closeHandler);

  // TODO: Use the dropdown from tailwin headless UI
  return (
    <Container
      // onMouseEnter={() => setIsDropdownOpen(true)}
      // onMouseLeave={() => setIsDropdownOpen(false)}
      ref={triggerEl}
      style={containerStyles}
    >
      <Trigger
        isButtonPrimary={isButtonPrimary}
        isOpen={isDropdownOpen}
        handleClick={() => setIsDropdownOpen(!isDropdownOpen)}
        handleFocus={handleFocus}
        handleBlur={handleBlur}
        triggerText={triggerText}
        triggerStyles={triggerStyles}
      />
      {isDropdownOpen && (
        <DropdownWrapper
          className="rounded"
          role="menu"
          aria-label="User Navigation"
          style={wrapperStyles}
        >
          {items.map((item, i) => (
            <DropdownItem
              className="first:rounded-t-sm last:rounded-b-sm"
              key={i}
              item={item}
              closeHandler={closeHandler}
              handleFocus={() => {
                setIsDropdownOpen(true);
                if (handleFocus) {
                  handleFocus();
                }
              }}
              handleBlur={() => {
                if (i === items.length - 1) {
                  if (handleBlur) {
                    handleBlur();
                  }
                  setIsDropdownOpen(false);
                }
              }}
            />
          ))}
        </DropdownWrapper>
      )}
    </Container>
  );
};

export default Dropdown;
