import * as React from 'react';
import styled from 'styled-components';

const SidebarLabelWrapper = styled.div`
  min-height: 22px;
  background-color: ${({ theme }) => theme.color.buttonPrimary};
  color: ${({ theme }) => theme.color.textPrimary};
  border-radius: 3px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
  margin-top: auto;
  margin-bottom: auto;
  padding: 14px 16px;
  line-height: 1;
  white-space: nowrap;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 700;
`;

const RevealHover = styled.div`
  position: relative;
  width: 0;
  opacity: 0;
  transition: opacity 250ms ease, transform 250ms ease;
  z-index: -1;
  transform: translateX(-16px);
  height: 30px;

  &:hover {
    opacity: 0.95;
    z-index: 0;
    transform: translateX(0);
  }
`;

const ChildWrapper = styled.div`
  display: flex;
  width: 100%;

  &:hover {
    + div {
      opacity: 0.95;
      z-index: 0;
      transform: translateX(0);
    }
  }
`;

const SidebarLabel: React.FC<React.PropsWithChildren<{ name: string }>> = ({ children, name }) => {
  return (
    <div className="flex items-center">
      <ChildWrapper>{children}</ChildWrapper>
      <RevealHover>
        <SidebarLabelWrapper>{name}</SidebarLabelWrapper>
      </RevealHover>
    </div>
  );
};

export default SidebarLabel;
