import styled, { css } from 'styled-components';
import { mobile } from 'styles/breakpoints';
import { SIDEBAR_WIDTH } from 'components/Sidebar/styles';
import {
  pageWidthStyles,
  ButtonLink,
  GhostButton,
  PAGE_CONTENT,
  CTAButtonLink,
} from 'styles/elements';
import companyStyle from 'styles/company';
import Link from 'components/Link';

const SECTION_SPACING = 12;

const sidebarRightStyles = css`
  margin-right: ${SIDEBAR_WIDTH}px;

  ${mobile()} {
    margin-left: 0;
    margin-right: 0;
  }
`;

const sidebarLeftStyles = css`
  margin-left: ${SIDEBAR_WIDTH}px;

  ${mobile()} {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const Wrapper = styled.footer<{ sidebar?: boolean; right?: boolean }>`
  background-color: ${({ theme }) => theme.color.backgroundSecondary};
  color: ${({ theme }) => theme.color.textSecondary};
  font-weight: 400;
  ${({ sidebar, right }) => (sidebar ? (right ? sidebarRightStyles : sidebarLeftStyles) : null)};
  padding-top: 40px;
  padding-right: 2%;
  padding-left: 2%;
`;

export const FooterSection = styled.div`
  color: ${({ theme }) => theme.color.textPrimary};
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  line-height: 1;

  ${mobile()} {
    justify-content: center;
  }
`;

export const FooterSectionText = styled(FooterSection)`
  font-size: 12px;
  color: ${({ theme }) => theme.color.textSecondary};
`;

export const Content = styled.div`
  ${pageWidthStyles};
  max-width: ${PAGE_CONTENT}px;
  padding: 16px 0;
  text-align: center;
  display: flex;

  &:last-of-type {
    border-top: 1px solid ${({ theme }) => theme.color.border};
  }

  ${mobile()} {
    flex-direction: column;
  }
`;

export const ContentGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  &:nth-of-type(2) {
    ${FooterSection} {
      justify-content: flex-end;
      align-items: flex-end;

      ${mobile()} {
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }

  ${mobile()} {
    margin-bottom: ${SECTION_SPACING}px;
    width: 100%;
  }
`;

export const TwitterButton = styled(ButtonLink)`
  background-color: ${companyStyle.color[companyStyle.Company.Twitter]};
  transition: background-color 150ms ease;
  padding: 7px 10px;
  border-radius: 3px;
  font-weight: 600;
  line-height: 1em;
  font-size: 0.78571429rem;
  margin-left: 8px;

  &:hover {
    background-color: ${companyStyle.hover[companyStyle.Company.Twitter]};
  }
`;

export const FooterLink = styled(Link)`
  color: ${({ theme }) => theme.color.textPrimary};
  font-size: 16px;
  display: inline-flex;
  margin-bottom: 28px;

  ${mobile()} {
    margin-top: 16px;
  }

  &:visited {
    color: ${({ theme }) => theme.color.textPrimary};
  }

  svg {
    margin-right: 16px;
  }
`;

export const FeedbackButton = styled(GhostButton)`
  font-size: 12px;
  padding: 8px 16px;

  svg {
    margin-left: 8px;
  }
`;

export const Divider = styled.span`
  margin-left: 8px;
  margin-right: 8px;
`;

export const Disclaimer = styled.div`
  font-weight: 300;
  color: #c2c3c5;
  font-size: 13px;
  margin: 0 auto;
  text-align: left;
  line-height: 18px;

  strong {
    font-weight: 700;
  }
`;

export const FooterLogo = styled.div`
  svg {
    height: 40px;
  }
`;

export const Copyright = styled.div`
  color: ${({ theme }) => theme.color.textPrimary};
  font-size: 14px;
`;

export const BuyNowButton = styled(CTAButtonLink)`
  font-weight: 700;
  font-size: 16px;
  height: 52px;
`;
